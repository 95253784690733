// register the plugin on vue
import ABI from '@/assets/abi/defi.json'
import store from '../store'
const Contract = require('web3-eth-contract');

export default class Vault {
  constructor() {
    Contract.setProvider(store.state.rpcUrl);
    this.contract = new Contract(ABI, store.state.vaultAddress);
  }

  async getIsAdmin(address){
    let result = await this.contract.methods.Isadmin(address).call();
    return result
  }

  async setAdmin(address){
    let extraData = await this.contract.methods.setAdmin(address)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async setMonthPercent(month, rate){
    rate = rate * 10 ** 18
    const rateString = rate.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.setMonthPercent(month, rateString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async getRewardPercent(){
    const rate = await this.contract.methods.ReferrerPercent().call()
    return parseInt(rate) / (10 ** 18)
  }

  async setRewardPercent(rate){
    rate = rate * 10 ** 18
    const rateString = rate.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.setReferrerPercent(rateString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async getRound() {
    let result = await this.contract.methods.Round().call()
    return result
  }

  async claimToken(token, amount) {
    const addresses = await this.getTokenAddress()
    const tokenAddress = token === 'egt' ? addresses.egtAddress : addresses.usdtAddress
    amount = amount * 10 ** 18
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.topadmin_withdrawal(tokenAddress, amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async getTokenAddress() {
    let egtAddress = await this.contract.methods.EGT().call()
    let usdtAddress = await this.contract.methods.USDT().call()
    return { egtAddress, usdtAddress }
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: store.state.vaultAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}