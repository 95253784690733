<template>
  <div class="no-auth-page d-flex flex-column align-center" style="margin-top: 100px;">
    <img class="mb-4" src="@/assets/img/img-no-auth.png" alt="" width="194">
    <div class="font-w-4 rem-12 lightGrey2--text text-center">不好意思<br>您尚未權限使用此功能</div>
  </div>
</template>
<script>
export default {
  name: '404'
}
</script>