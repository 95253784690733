<template>
  <div class="reward-setting-edit">
    <v-container class="pa-5">
      <div class="rem-6 font-w-4 primary--text mb-6 d-md-flex justify-space-between align-center d-none">
        <v-icon @click="$router.go(-1)" color="primary">mdi-chevron-left</v-icon>
        <v-spacer></v-spacer>
        獎金參數設定
        <v-spacer></v-spacer>
      </div>

      <div class="deposit-card d-flex justify-space-between align-center py-5 pr-3 pl-4 mb-5">
        <div class="d-flex align-center">
          <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${$route.params.depositToken}.svg`)}`" :alt="$route.params.depositToken">
          <div class="font-share-tech font-w-4">
            <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ $route.params.depositToken.toUpperCase() }}</span>
            <span class="rem-3 rem-sm-4 primary--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
        </div>
      </div>

      <div class="reward-edit-block white rounded py-4 px-3 mb-2 black--text rem-3 font-share-tech d-flex justify-space-between align-center">
        <div class="font-weight-bold">目前獎金參數為</div>
        <div class="d-flex align-center">
          <span class="primary--text d-flex align-center mr-3">
            {{ currReward }} %
          </span>
          <span>{{ $route.params.interestToken.toUpperCase() }}</span>
        </div>
      </div>
      <div class="reward-edit-block white rounded py-4 px-3 mb-2 black--text rem-3 font-share-tech d-flex justify-space-between align-center">
        <div class="font-weight-bold">調整後獎金</div>
        <div class="d-flex align-center">
          <span class="primary--text d-flex align-center mr-3">
            <v-form ref="rewardEditform" lazy-validation>
              <v-text-field
                hide-details
                class="pt-0"
                v-model="newReward"
                :rules="rateRules"
              ></v-text-field>
            </v-form>
          </span>
          <span>{{ $route.params.interestToken.toUpperCase() }}</span>
        </div>
      </div>

      <btnPill class="rem-6 mb-2" btnText="立即調整" width="100%" :disabled="!newReward" @clickBtn="edit()" />
      <div class="text-right small-rem-9 mb-n1 font-w-4 lightGrey2--text">審核帳號：{{ $store.state.userInfo.account }}</div>
    </v-container>
  </div>
</template>
<script>
import base from '@/mixin/base'
import btnPill from '@/components/btn-pill.vue'
export default {
  mixins: [base],
  data(){
    return {
      currReward: 0,
      newReward: null
    }
  },
  components: {
    btnPill
  },
  methods: {
    async edit() {
      if (!this.$refs.rewardEditform.validate()) { return }

      if (!this.$store.state.chainId) {
        this.$toasted.error('請切換到幣安智能鏈')
        return
      }

      const result = await this.$vault.setRewardPercent(this.newReward)
      if (result.txHash){
        this.$toasted.show('交易已送出')
        const data = {
          original_percent: this.currReward,
          new_percent: this.newReward * 100,
          address: this.$store.state.account,
          tx: result.txHash
        }
        const apiResult = await this.$store.dispatch('setReward', data)
        if (apiResult.status === 200) {
          this.$toasted.show('更新成功')
          this.$router.push({name: 'RewardSetting', params: {interestToken: this.$route.params.interestToken, depositToken: this.$route.params.depositToken}})
        } else {
          this.$toasted.error('更新失敗')
        }
      }else if (result.code === 4001){
        this.$toasted.error('使用者拒絕連線')
      }
    },
    async getCurrReward () {
      this.currReward = await this.$vault.getRewardPercent() * 100
    }
  },
  async mounted(){
    await this.getCurrReward()
  }
}
</script>
<style lang="scss">
.reward-setting-edit {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    background: white;
    border-radius: 11px;
    margin: 40px auto;
  }

  .deposit-card {
    background: white;
    border-radius: 5px;
    border: 1px solid var(--v-primary-base);
  }
  
  .reward-edit-block {
    @include dai_vuetify_sm_min {
      box-shadow: 0px 2px 4px 0px #00000040;
    }
  }
}
</style>