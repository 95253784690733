<template>
  <section class="whitelist-index py-md-4 px-md-14 pa-5">
    <div class="font-w-7 primary--text d-flex justify-space-between align-end mb-md-9 mb-3">
      <div class="d-md-block d-none">
        <div class="rem-8">Whitelist Management</div>
        <div class="rem-24">白名單管理</div>
      </div>
      <div :style="{width: $store.state.nowWidth >= 960 ? '280px' : '100%'}">
        <v-text-field
          v-model="search"
          class="mb-3 grey--text search-input"
          background-color="white"
          solo
          flat
          dense
          outlined
          hide-details
          append-icon="mdi-magnify"
          placeholder="輸入帳號搜尋"
        ></v-text-field>
      </div>
    </div>

    <div class="d-flex justify-end mb-4">
      <v-btn depressed dark width="90" height="25" color="primary" @click="showDialog('create')">新增白名單</v-btn>
    </div>

    <dataTable :data="filterList" :column="column">
      <template v-slot:action="{item}">
        <img class="can-click" src="@/assets/img/icon-delete.svg" alt="delete" @click="currItem = item; showDialog('delete');">
      </template>
    </dataTable>

    <inputDialog
      :dialogShow.sync="dialogShow"
      :color="`var(--v-${dialogType === 'create' ? 'primary' : 'lightPrimary1'}-base)`"
      :title="dialogType === 'create' ? '新增白名單' : '再次確認刪除白名單'"
      :btnText="dialogType === 'create' ? '確認新增' : '確認刪除'"
      :btnColorSetting="dialogType === 'create' ? {} : {
        textColor: 'white--text',
        bgColor: 'lightPrimary1',
        noOutline: true
      }"
      @clickBtn="confirm()"
    >
      <template v-slot:content>
        <v-form ref="form" class="py-3 px-4" lazy-validation v-if="dialogType === 'create'">
          
          <v-text-field v-model="editData.name" class="mb-2" outlined dense placeholder="請輸入白名單姓名" hide-details="auto" :rules="[dataRules]"></v-text-field>
          <v-text-field v-model="editData.address" class="mb-7" outlined dense placeholder="請輸入白名單錢包地址" hide-details="auto" :rules="accountRules"></v-text-field>

          <div class="text-right small-rem-9 mb-n1 font-w-4 lightGrey3--text">審核帳號：{{ $store.state.userInfo.account }}</div>
        </v-form>

        <div v-else class="text-center font-w-4 pa-5">
          <div class="rem-6 lightGrey2--text mb-3">{{ currItem.name }}</div>
          <div class="rem-2">{{ currItem.address }}</div>
        </div>
      </template>
    </inputDialog>
  </section>
</template>
<script>
import dataTable from '@/components/dataTable.vue'
import inputDialog from '@/components/inputDialog.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      whitelistList: [],
      search: '',
      dialogShow: false,
      dialogType: 'create',
      editData: {
        name: '',
        address: '',
      },
      currItem: {}
    }
  },
  components: {
    dataTable,
    inputDialog
  },
  watch: {
    dialogShow(newVal) {
      if (!newVal && this.dialogType === 'create') {
        this.editData.name = ''
        this.editData.address = ''
        this.$refs.form.resetValidation()
      }
    }
  },
  computed: {
    filterList() {
      if (this.search) {
        return this.whitelistList.filter(item => item.address.includes(this.search) || item.name.includes(this.search))
      } else {
        return this.whitelistList
      }
    },
    column() {
      return [
        {text: '姓名', value: 'name', grid: 3, gridMd: 2, class: "text-center"},
        {text: '地址', value: this.$store.state.nowWidth > 960 ? 'address' : 'shortAddress', grid: 3, gridMd: 5, class: "text-center"},
        {text: '通過時間', value: 'create_time', grid: 5, gridMd: 4, class: "text-center"},
        {text: '編輯', value: '', grid: 1, gridMd: 1, class: "text-center"},
      ]
    }
  },
  methods: {
    showDialog(type) {
      this.dialogType = type
      this.dialogShow = true
    },
    async confirm() {
      if (this.dialogType === 'create') {
        if (this.$refs.form.validate()) {
          let result = await this.$store.dispatch('addWhitelistList', {
            name: this.editData.name,
            address: this.editData.address.toLowerCase()
          })
          if (result.status === 200) {
            this.$toasted.show('新增成功')
            this.dialogShow = false
            await this.getWhitelistList()
          } else if (result.status === 685) {
            this.$toasted.error('該地址已註冊')
          } else {
            this.$toasted.error('新增失敗')
          }
        }
      } else {
        let result = await this.$store.dispatch('deleteWhitelistList', {
          id: this.currItem.id,
          account: this.currItem.name,
          address: this.currItem.address
        })
        if (result.status === 200) {
          this.$toasted.show('刪除成功')
          this.dialogShow = false
          await this.getWhitelistList()
        } else {
          this.$toasted.error('刪除失敗')
        }
      }
    },
    async getWhitelistList() {
      let result = await this.$store.dispatch('getWhitelistList')
      try {
        if (result.status === 200) {
          result.data.forEach(item => {
            item.create_time = this.timestampToTime(item.create_time * 1000)
            if (this.$store.state.nowWidth < 960) {
              item.shortAddress = this.convertShortAddress(item.address)
            }
          })
          this.whitelistList = result.data
        }
      } catch(error) {
        console.log('error', error)
      }
    }
  },
  async mounted(){
    await this.getWhitelistList()
  }
}
</script>
<style lang="scss">
.whitelist-index {}
</style>