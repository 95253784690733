import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#24488C',
        lightPrimary1: '#44ADCF',
        lightPrimary2: '#F0CD8F',
        grey: '#636363',
        lightGrey1: '#AEAEAE',
        lightGrey2: '#989898',
        lightGrey3: '#B7B7B7',
        darkGrey: '#2F2B2B',
        blackGrey: '#232324',
        success: '#00A77B',
        error: '#BF3232',
      },
    },
    options: {
      customProperties: true
    }
  },
});
