<template>
  <v-dialog class="input-dialog" width="287" persistent v-model="dialogShow">
    <template v-if="isCustom">
      <slot name="custom"></slot>
    </template>
    <template v-else>
      <v-icon class="close-icon rounded-circle white--text pa-1 can-click" small @click="$emit('update:dialogShow', false)">mdi-close</v-icon>
      <v-card class="input-dialog">
        <v-card-title class="white--text font-w-4 rem-6 justify-center" :style="{backgroundColor: color}">{{ title }}</v-card-title>

        <slot name="content"></slot>

        <btnPill
          class="mx-4 mb-3"
          width="calc(100% - 32px)"
          :btnText="btnText"
          :textColor="btnColorSetting.textColor"
          :bgColor="btnColorSetting.bgColor"
          :noOutline="btnColorSetting.noOutline"
          @clickBtn="$emit('clickBtn')"
        />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import btnPill from '@/components/btn-pill.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    title: String,
    color: String,
    btnText: String,
    isCustom: {
      type: Boolean,
      default: false
    },
    btnColorSetting: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    btnPill
  }
}
</script>

<style lang="scss">
.v-dialog {
  position: relative;
  overflow: visible;
  .close-icon {
    position: absolute !important;
    width: 21px;
    height: 21px;
    top: -10px;
    right: -10px;
    z-index: 1000;
    background-color: #BC1919;
  }
}
</style>