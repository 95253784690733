<template>
  <div class="reward-setting-history">
    <v-container class="pa-5">
      <div class="rem-6 font-w-4 primary--text mb-6 d-md-flex justify-space-between align-center d-none">
        <v-icon @click="$router.go(-1)" color="primary">mdi-chevron-left</v-icon>
        <v-spacer></v-spacer>
        獎金參數設定
        <v-spacer></v-spacer>
      </div>

      <div class="deposit-card d-flex justify-space-between align-center py-5 pr-3 pl-4 mb-7">
        <div class="d-flex align-center">
          <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${$route.params.depositToken}.svg`)}`" :alt="$route.params.depositToken">
          <div class="font-share-tech font-w-4">
            <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ $route.params.depositToken.toUpperCase() }}</span>
            <span class="rem-3 rem-sm-4 primary--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
        </div>
        <v-btn
          class="border-radius-3 primary lightPrimary2--text font-w-4 px-2"
          depressed
          height="24"
          @click.stop="$router.push({name: 'RewardSettingEdit', params: {interestToken: $route.params.interestToken, depositToken: $route.params.depositToken}})"
        >
          立即調整
        </v-btn>
      </div>

      <div class="rem-6 font-w-4 primary--text mb-2">{{ $route.params.depositToken.toUpperCase() }}/{{ $route.params.interestToken.toUpperCase() }} 獎金調整紀錄 </div>
      <v-card outlined>
        <v-row class="rem-2 font-w-4 lightGrey2--text text-center" style="background: #FCFCFC;" no-gutters>
          <v-col class="py-4" cols="9">獎金調整</v-col>
          <v-col class="py-4" cols="3">編輯者</v-col>
        </v-row>
        <v-row
          no-gutters
          class="font-w-4 lightGrey2--text text-center"
          v-for="(item, i) in history"
          :key="i"
        >
          <v-col class="rem-0 py-3 d-flex flex-column justify-center align-start" cols="9">
            <div class="ml-2 mb-2">
              <span class="mr-1">獎金新調整</span>
              <div class="reward-input d-inline-block rounded w-100">
                {{ (item.newPercent || 0).toFixed(1) }}% <span style="font-size: 6px !important;">USDT</span>
              </div>
            </div>
            <div class="ml-2 mb-2">
              <span class="mr-1">原獎金參數</span>
              <div class="reward-input d-inline-block rounded w-100">
                {{ (item.currPercent || 0).toFixed(1) }}% <span style="font-size: 6px !important;">USDT</span>
              </div>
            </div>
          </v-col>
          <v-col class="rem-2 py-3 px-2 d-flex flex-column justify-center align-center break-all" cols="3">
            <div class="mb-1">{{ timestampToDate(item.create_time * 1000) }}</div>
            <div>{{ item.name }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      history: [],
    }
  },
  async mounted(){
    let result = await this.$store.dispatch('getRewardHistory')
    if (result.status === 200) {
      this.history = result.data.map(item => ({
        ...item,
        createTime: item.create_time,
        newPercent: item.new_percent,
        currPercent: item.original_percent,
      }))
      this.history = this.history.sort((a, b) => b.create_time - a.create_time)
    }
  }
}
</script>
<style lang="scss">
.reward-setting-history {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    background: white;
    border-radius: 11px;
    margin: 40px auto;
  }

  .deposit-card {
    background: white;
    border-radius: 5px;
    border: 1px solid var(--v-primary-base);
  }

  .row {
    border-bottom: 1px solid #EBEBEB;
    .reward-input {
      background: #F3F3F3;
      padding: 2px 10px;
      min-width: 52px;
    }
  }
}
</style>