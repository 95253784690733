<template>
  <section class="authority-index py-md-4 px-md-14 pa-5">
    <div class="font-w-7 primary--text d-flex justify-space-between align-end mb-md-9 mb-3">
      <div class="d-md-block d-none">
        <div class="rem-8">Authority Management</div>
        <div class="rem-24">權限名單管理</div>
      </div>
      <div :style="{width: $store.state.nowWidth >= 960 ? '280px' : '100%'}">
        <v-text-field
          v-model="search"
          class="mb-3 grey--text search-input"
          background-color="white"
          solo
          flat
          dense
          outlined
          hide-details
          append-icon="mdi-magnify"
          placeholder="輸入帳號搜尋"
        ></v-text-field>
      </div>
    </div>

    <div class="d-flex justify-end mb-4">
      <v-btn depressed dark width="90" height="25" color="primary" @click="showDialog('create')">新增管理者</v-btn>
    </div>

    <dataTable :data="filterList" :column="column">
      <template v-slot:action="{item}">
        <img class="can-click mr-5" src="@/assets/img/icon-edit.svg" alt="edit" @click="currItem = item; showDialog('edit')">
        <img class="can-click" src="@/assets/img/icon-delete.svg" alt="delete" @click="currItem = item; showDialog('delete');">
      </template>
    </dataTable>

    <inputDialog
      :dialogShow.sync="dialogShow"
      :color="`var(--v-${dialogType === 'create' ? 'primary' : 'error'}-base)`"
      :title="dialogType === 'create' ? '新增管理者帳號' : '刪除管理者帳號'"
      :btnText="dialogType === 'create' ? '確認' : '確認刪除'"
      :isCustom="dialogType === 'edit'"
      :btnColorSetting="dialogType === 'create' ? {} : {
        bgColor: 'error'
      }"
      @clickBtn="confirm()"
    >
      <template v-slot:content>
        <v-form ref="form" class="py-3 px-4" lazy-validation v-if="dialogType === 'create'">
          
          <v-text-field v-model="editData.account" class="mb-2" outlined dense placeholder="請設定管理者帳號" hide-details="auto" :rules="[dataRules]"></v-text-field>
          <v-text-field v-model="editData.address" class="mb-7" outlined dense placeholder="請設定管理者地址" hide-details="auto" :rules="accountRules"></v-text-field>

          <div class="text-right small-rem-9 mb-n1 font-w-4">審核帳號：{{ $store.state.userInfo.account }}</div>
        </v-form>

        <div v-else class="text-center font-w-4 rem-12 pa-5 mb-10">
          <div class="error--text font-weight-bold   mb-5">請再次確認刪除用戶</div>
          <div class="primary--text mb-8">{{ currItem.account }}</div>
          <div class="lightGrey2--text mb-5">{{ currItem.address }}</div>
        </div>
      </template>
      <template v-slot:custom>
        <v-card class="pa-3">
          <div class="d-flex justify-end">
            <v-icon color="black" small @click="dialogShow = false">mdi-close</v-icon>
          </div>

          <div class="d-flex flex-column align-center mx-8">
            <img class="mb-4" :src="`${require('@/assets/img/img-edit-authority.png')}`" alt="" width="100px">
            <div class="lightGrey2--text font-w-4 rem-4 mb-2">請選擇使用者權限</div>
            <div class="font-w-7 rem-8 mb-6">{{ currItem.account }}</div>

            <v-btn
              class="mb-3"
              :style="{marginBottom: i === authItems.length - 1 ? '100px !important' : '12px'}"
              v-for="(item, i) in authItems"
              :key="item.value"
              depressed
              dark
              width="100%"
              :outlined="newAuth.includes(item.value)"
              :color="newAuth.includes(item.value) ? 'primary' : '#BBBBBB'"
              @click="clickAuth(item.value)"
            >
              {{ item.text }}
            </v-btn>

            <btnPill btnText="確認保存" width="100%" @clickBtn="editPermission()" />
          </div>
        </v-card>
      </template>
    </inputDialog>
  </section>
</template>
<script>
import dataTable from '@/components/dataTable.vue'
import inputDialog from '@/components/inputDialog.vue'
import btnPill from '@/components/btn-pill.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      adminList: [],
      search: '',
      dialogShow: false,
      dialogType: 'create',
      editData: {
        account: '',
        address: '',
      },
      currItem: {},
      timer: null,
      authItems: [
        {text: '白名單管理', value: 1},
        {text: '戰情分析', value: 2},
        {text: '權限名單管理', value: 3},
      ],
      newAuth: [],
    }
  },
  components: {
    dataTable,
    btnPill,
    inputDialog
  },
  watch: {
    dialogShow(newVal) {
      if (!newVal) {
        if (this.dialogType === 'create') {
          this.editData.account = ''
          this.editData.address = ''
          this.$refs.form.resetValidation()
        } else {
          this.newAuth = []
        }
      }
    },
    currItem(newVal) {
      this.newAuth = newVal.permission.map(item => parseInt(item))
    }
  },
  computed: {
    filterList() {
      if (this.search) {
        return this.adminList.filter(item => item.address.includes(this.search) || item.account.includes(this.search))
      } else {
        return this.adminList
      }
    },
    column() {
      return [
        {text: '帳號', value: 'account', grid: 3, gridMd: 2, class: "text-center"},
        {text: '地址', value: this.$store.state.nowWidth > 960 ? 'address' : 'shortAddress', grid: 3, gridMd: 5, class: "text-center"},
        {text: '通過時間', value: 'setting_time', grid: 5, gridMd: 4, class: "text-center"},
        {text: '編輯', value: '', grid: 1, gridMd: 1, class: "text-center"},
      ]
    }
  },
  methods: {
    showDialog(type) {
      this.dialogType = type
      this.dialogShow = true
    },
    async confirm() {
      if (this.dialogType === 'create') {
        if (this.$refs.form.validate()) {
          if (this.$store.state.chainId){
            let isAdmin = await this.$vault.getIsAdmin(this.editData.address)
            if (isAdmin) {
              this.$toasted.error('該地址已是管理員')
              return;
            }

            let result = await this.$vault.setAdmin(this.editData.address)
            // console.log('result', result)
            if (result.txHash){
              this.$store.commit('updateLoading', {isShow: true, text: '新增中，請稍後'})
              this.timer = window.setInterval(async () => {
                isAdmin = await this.$vault.getIsAdmin(this.editData.address)
                if (isAdmin) {
                  window.clearInterval(this.timer)

                  this.editData.address = this.editData.address.toLowerCase()
                  result = await this.$store.dispatch('addAdminList', this.editData)
                  if (result.status === 200) {
                    this.$toasted.show('新增成功')
                    this.dialogShow = false
                    await this.getAdminList()
                  } else if (result.status === 685) {
                    this.$toasted.error('該地址已是管理員')
                  } else {
                    this.$toasted.error('新增失敗')
                  }

                  this.$store.commit('updateLoading', {isShow: false, text: ''})
                }
              }, 1000)
            }else if (result.code === 4001){
              this.$toasted.error('使用者拒絕連線')
            }
          }else{
            this.$toasted.error('請切換到幣安智能鏈')
          }
        }
      } else {
        if (this.$store.state.chainId){
          let isAdmin = await this.$vault.getIsAdmin(this.currItem.address)
          if (!isAdmin) {
            this.$toasted.error('該地址非管理員')
            return;
          }

          let result = await this.$vault.setAdmin(this.currItem.address)
          // console.log('result', result)
          if (result.txHash){
            this.$store.commit('updateLoading', {isShow: true, text: '刪除中，請稍後'})
            this.timer = window.setInterval(async () => {
              isAdmin = await this.$vault.getIsAdmin(this.currItem.address)
              if (!isAdmin) {
                window.clearInterval(this.timer)

                result = await this.$store.dispatch('deleteAdminList', {
                  id: this.currItem.id,
                  account: this.currItem.account,
                  address: this.currItem.address,
                  permission: 9999
                })
                if (result.status === 200) {
                  this.$toasted.show('刪除成功')
                  this.dialogShow = false
                  await this.getAdminList()
                } else {
                  this.$toasted.error('刪除失敗')
                }

                this.$store.commit('updateLoading', {isShow: false, text: ''})
              }
            }, 1000)
          }else if (result.code === 4001){
            this.$toasted.error('使用者拒絕連線')
          }
        }else{
          this.$toasted.error('請切換到幣安智能鏈')
        }
      }
    },
    async getAdminList() {
      let result = await this.$store.dispatch('getAdminList')
      try {
        if (result.status === 200) {
          result.data.forEach(item => {
            item.setting_time = this.timestampToTime(item.setting_time * 1000)
            if (this.$store.state.nowWidth < 960) {
              item.shortAddress = this.convertShortAddress(item.address)
            }
          })
          this.adminList = result.data
        }
      } catch(error) {
        console.log('error', error)
      }
    },
    clickAuth(value) {
      let index = this.newAuth.findIndex(item => item === value)
      if (index !== -1) {
        this.newAuth.splice(index, 1)
      } else {
        this.newAuth.push(value)
      }
      this.$forceUpdate()
    },
    async editPermission() {
      this.$store.commit('updateLoading', {isShow: true, text: '更新中，請稍後'})

      try {
        this.authItems.forEach(async (item) => {
          if (this.newAuth.includes(item.value) && !this.currItem.permission.includes(item.value)) {
            await this.$store.dispatch('addAdminList', {
              id: this.currItem.id,
              account: this.currItem.account,
              address: this.currItem.address,
              permission: item.value
            })
          } else if (!this.newAuth.includes(item.value) && this.currItem.permission.includes(item.value)) {
            await this.$store.dispatch('deleteAdminList', {
              account: this.currItem.account,
              address: this.currItem.address,
              permission: item.value
            })
          }
        })
      } catch (error) {
        this.$toasted.error('發生錯誤，請重試')
        console.log('error', error)
      }

      this.$store.commit('updateLoading', {isShow: false, text: ''})
      this.$toasted.show('更新完成')
      this.dialogShow = false
      await this.getAdminList()
      this.newAuth = []
    }
  },
  async mounted(){
    await this.getAdminList()
  }
}
</script>
<style lang="scss">
.authority-index {}
</style>