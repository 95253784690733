<template>
  <div class="rate-setting-index">
    <v-container class="py-5">
      <div class="rem-6 font-w-4 primary--text mb-6 text-center d-md-block d-none">
        利率參數設定
      </div>

      <div class="deposits-wrap px-sm-3">
        <div
          class="deposit-card d-flex justify-space-between align-center py-5 pr-3 pl-4 mb-3 can-click"
          v-for="(item, i) in $store.state.depositTokens"
          :key="i"
          @click="$router.push({name: 'RateSettingRecord', params: {interestToken: currInterestToken, depositToken: item.token}})"
        >
          <div class="d-flex align-center">
            <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${item.token}.svg`)}`" :alt="item.token">
            <div class="font-share-tech font-w-4">
              <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ item.token.toUpperCase() }}</span>
              <span class="rem-3 rem-sm-4 primary--text">/{{ currInterestToken.toUpperCase() }}</span>
            </div>
          </div>
          <v-btn
            class="border-radius-3 primary lightPrimary2--text font-w-4 px-2"
            depressed
            height="24"
            @click.stop="$router.push({name: 'RateSettingEdit', params: {interestToken: currInterestToken, depositToken: item.token}})"
          >
            立即調整
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data(){
    return {
      currInterestToken: '',
    }
  },
  async mounted(){
    this.currInterestToken = this.$store.state.interestTokens[0].token
  }
}
</script>
<style lang="scss">
.rate-setting-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    background: white;
    border-radius: 11px;
    margin: 40px auto;
  }
  .index-card-wrap {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .index-card-scroll {
      width: fit-content; 
      .index-card {
        width: 142px;
      }
    }
  }

  .deposit-card {
    background: white;
    border-radius: 5px;
    border: 1px solid var(--v-primary-base);
  }
}
</style>