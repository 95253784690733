<template>
  <div class="analyze-index">
    <v-container class="py-5">
      <div class="rem-6 font-w-4 primary--text mb-6 text-center d-md-block d-none">
        戰情分析
      </div>

      <v-card color="white" elevation="0" class="mx-sm-2 py-2 px-3 mb-5 date-select-wrap primary--text d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            close-on-content-click
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                prepend-icon="mdi-calendar-month-outline"
                color="primary"
                readonly
                solo
                flat
                hide-details
                style="width: 130px;"
                background-color="transparent"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="2022-01-01"
              @change="saveStart"
            ></v-date-picker>
          </v-menu>

          <span class="ml-n3 mr-3">—</span>

          <v-menu
            ref="endMenu"
            v-model="endMenu"
            close-on-content-click
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                prepend-icon="mdi-calendar-month-outline"
                color="primary"
                readonly
                solo
                flat
                hide-details
                style="width: 130px;"
                background-color="transparent"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              :min="startDate"
              @change="saveEnd"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center primary--text can-click" @click="exportBalance()">
          匯出
          <v-icon color="primary">mdi-menu-down</v-icon>
        </div>
      </v-card>

      <v-tabs v-model="currTab" background-color="transparent" class="px-2">
        <v-tabs-slider color="primary" class="mx-auto mt-n2" style="width: 18px"></v-tabs-slider>
        <v-tab
          v-for="tab in tabItems"
          :key="`tab${tab.value}`"
          :class="tab.value === currTab ? 'primary--text' : 'lightGrey2--text'"
        >
          {{ tab.text }}
        </v-tab>

        <v-tab-item
          v-for="tab in tabItems"
          :key="`tabItem${tab.value}`"
          class="analyze-wrap"
        >
          <div
            class="analyze-card d-flex justify-space-between align-center py-3 pr-3 pl-4 mt-3 can-click"
            v-for="(item, i) in $store.state.depositTokens"
            :key="i"
            @click="toToken(item)"
          >
            <div class="d-flex align-center">
              <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${item.token}.svg`)}`" :alt="item.token">
              <div class="font-share-tech font-w-4">
                <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ item.token.toUpperCase() }}</span>
                <span class="rem-3 rem-sm-4 primary--text">/{{ currInterestToken.toUpperCase() }}</span>
              </div>
            </div>
            <div>
              <div class="lightPrimary2--text">{{ (totalAmount[tab.value] || 0).toLocaleString() }} {{ item.token.toUpperCase() }}</div>
              <div class="lightGrey2--text">≈ {{ (totalAmount[tab.value] * price[item.token] || 0).toLocaleString() }} {{ currInterestToken.toUpperCase() }}</div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>
<script>
import base from '@/mixin/base'
export default {
  mixins: [base],
  name: "Analyze",
  data(){
    return {
      currInterestToken: '',
      startMenu: null,
      endMenu: null,
      startDate: null,
      endDate: null,
    }
  },
  watch: {
    startDate: {
      handler: async function() {
        await this.getTokenDetails()
      }
    },
    endDate: {
      handler: async function() {
        await this.getTokenDetails()
      }
    },
  },
  methods: {
    saveStart(date) {
      this.$refs.startMenu.save(date)
    },
    saveEnd(date) {
      this.$refs.endMenu.save(date)
    },
    async balanceEqualInterestToken(interestToken, depositToken, amount) {
      let data = {}
      data.token = depositToken === 'egt' ? 'ethereum' : depositToken
      data.currency = interestToken.includes('usd') ? 'usd' : interestToken
      let result = await this.$store.dispatch('getTokenPrice', data)
      if (depositToken === 'egt') {
        return result[data.token][data.currency] * amount / 1000
      } else {
        return result[data.token][data.currency] * amount
      }
    },
    exportBalance() {
      const startTime = Date.parse(this.startDate) / 1000 / (60 * 60 * 24)
      const endTime = Date.parse(this.endDate) / 1000 / (60 * 60 * 24)

      window.open(`${this.$store.state.backendUrl}/analyze${this.$store.state.backendVersion}/download_home?start=${startTime}&end=${endTime}`)
    },
    toToken(item) {
      let routeItme = {
        name: 'Analyze-Token',
        query: {},
        params: {
          interestToken: this.currInterestToken,
          depositToken: item.token
        }
      }

      if (this.startDate !== this.timestampToFirstDayofMonth(Date.now())) {
        routeItme.query.start = Date.parse(this.startDate) / 1000 / (60 * 60 * 24)
      }

      if (this.endDate !== this.timestampToDate(Date.now())) {
        routeItme.query.end = Date.parse(this.endDate) / 1000 / (60 * 60 * 24)
      }

      this.$router.push(routeItme)
    }
  },
  async mounted(){
    await this.updateDepositTokenPrice()
    this.currInterestToken = this.$store.state.interestTokens[0].token

    this.endDate = this.timestampToDate(Date.now())
    this.startDate = this.timestampToFirstDayofMonth(Date.now())

    await this.getTokenDetails()
  }
}
</script>
<style lang="scss">
.analyze-index {
  .index-card-wrap {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .index-card-scroll {
      width: fit-content; 
      .index-card {
        width: 142px;
      }
    }
  }
}
</style>