<template>
  <div class="admin-index">
    <v-container>
      index
    </v-container>
  </div>
</template>
<script>
export default {
  async created(){
    this.$router.push('/rate-setting')
  }
}
</script>
<style lang="scss">
.admin-index {
  
}
</style>